import axios from 'axios';
import TokenProvider from './token-provider';
import { AuthToken } from 'src/domain/commerce/token/auth-token';
import { COOKIE_DEFAULT } from '@utils/constants/cookies';

declare module 'axios' {
  export interface AxiosRequestConfig {
    includeBasketJson?: boolean;
    includeToken?: boolean;
  }
}

const axiosInterceptor = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const accessToken = response?.headers?.['authentication-token'];
      if (accessToken) {
        const existingToken = TokenProvider.getToken();
        const newToken: AuthToken = {
          access_token: accessToken,
          expires_in: COOKIE_DEFAULT.EXPIRE_TIME_IN_SECONDS,
          token_type: COOKIE_DEFAULT.TOKEN_TYPE,
          is_anonymous: existingToken ? existingToken.is_anonymous : true,
        };
        TokenProvider.setToken(newToken);
      }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
};

export default axiosInterceptor;

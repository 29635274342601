export const COOKIE_KEYS = {
  API_TOKEN: 'apiToken',
  BASKET_ID: 'cc-cmsid',
};

export const COOKIE_DEFAULT = {
  TOKEN_TYPE: 'user',
  EXPIRE_TIME_IN_SECONDS: 3600,
  CREATOR: 'next',
};

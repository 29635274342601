import Cookies from 'js-cookie';
import { AuthToken } from 'src/domain/commerce/token/auth-token';
import { COOKIE_DEFAULT, COOKIE_KEYS } from '@utils/constants/cookies';

const isServer = () => {
  return typeof window === 'undefined';
};

const getExpireTime = (timeInSeconds: number) => {
  return new Date(new Date().getTime() + timeInSeconds * 1000);
};

export interface ITokenProvider {
  getToken(): AuthToken | undefined;
  setToken(token: AuthToken): boolean;
}

const TokenProvider = (): ITokenProvider => {
  const getToken = (): AuthToken | undefined => {
    if (isServer()) {
      return undefined;
    }

    const cookie = Cookies.get(COOKIE_KEYS.API_TOKEN);
    if (!cookie) {
      return undefined;
    }
    const token = JSON.parse(decodeURIComponent(cookie));
    return {
      access_token: token.apiToken,
      expires_in: COOKIE_DEFAULT.EXPIRE_TIME_IN_SECONDS,
      token_type: token.type ?? COOKIE_DEFAULT.TOKEN_TYPE,
      is_anonymous: token.isAnonymous,
    };
  };

  const setToken = (token: AuthToken): boolean => {
    if (isServer()) {
      return false;
    }

    const newData = {
      apiToken: token.access_token,
      type: token.token_type,
      isAnonymous: token.is_anonymous,
      creator: COOKIE_DEFAULT.CREATOR,
    };

    Cookies.set(COOKIE_KEYS.API_TOKEN, JSON.stringify(newData), {
      expires: getExpireTime(token.expires_in),
      path: '/',
      secure: true,
    });

    return true;
  };

  return {
    getToken,
    setToken,
  };
};

export default TokenProvider();

import { CartProvider } from './cart/cart.context';
import { ModalProvider } from '@components/common/modal/modal.context';

export const ManagedUIContext: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <CartProvider>
    <ModalProvider>{children}</ModalProvider>
  </CartProvider>
);

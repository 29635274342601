export const getAppConfig = () => ({
  ALGOLIA_APPID: process.env.NEXT_PUBLIC_ALGOLIA_APPID ?? '',
  ALGOLIA_APIKEY: process.env.NEXT_PUBLIC_ALGOLIA_APIKEY ?? '',
  ALGOLIA_INDEXNAME: process.env.NEXT_PUBLIC_ALGOLIA_INDEXNAME ?? '',
  ALGOLIA_BLOG_INDEXNAME: process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX_NAME ?? '',
  ALGOLIA_POPULAR_SEARCH_INDEXNAME:
    process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_SEARCH_INDEXNAME ??
    'smart_store_products_query_suggestions',
  ALGOLIA_PRICE_ASC_INDEXNAME:
    process.env.NEXT_PUBLIC_ALGOLIA_PRICE_ASC_INDEXNAME ?? '',
  ALGOLIA_PRICE_DESC_INDEXNAME:
    process.env.NEXT_PUBLIC_ALGOLIA_PRICE_DESC_INDEXNAME ?? '',
  DISABLE_ALGOLIA_FETCH: process.env.NEXT_PUBLIC_DISABLE_ALGOLIA_FETCH ?? false,
  ALGOLIA_INSIGHTS_URL:
    process.env.NEXT_PUBLIC_ALGOLIA_INSIGHTS_URL ??
    'https://insights.algolia.io/1/events',
  ENV: process.env.NODE_ENV ?? 'development',
});

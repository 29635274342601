import TokenProvider from '@framework/utils/token-provider';
import { COOKIE_DEFAULT } from '@utils/constants/cookies';

const useCookieUpdate = () => {
  const token = TokenProvider.getToken();

  if (token?.token_type && token?.token_type !== COOKIE_DEFAULT.CREATOR) {
    const newToken = { ...token };
    newToken.token_type = COOKIE_DEFAULT.CREATOR;
    TokenProvider.setToken(newToken);
  }
};

export default useCookieUpdate;

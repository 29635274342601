import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { ManagedUIContext } from '@contexts/ui.context';
import ManagedModal from '@components/common/modal/managed-modal';
import ManagedDrawer from '@components/common/drawer/managed-drawer';
import { useEffect, useRef } from 'react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from '@components/seo/default-seo';
import axiosInterceptor from '@framework/utils/axiosInterceptors';
import { createRandomString } from '@utils/tools';

// external
import 'react-toastify/dist/ReactToastify.css';

// base css file
import '@styles/scrollbar.css';
import '@styles/swiper-carousel.css';
import '@styles/custom-plugins.css';
import '@styles/tailwind.css';
import '@styles/themes.scss';
import { getDirection } from '@utils/get-direction';
import useCookieUpdate from 'src/hooks/useCookieUpdate';

const Noop: React.FC<React.PropsWithChildren> = ({ children }) => children;

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const queryClientRef = useRef<any>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }
  const router = useRouter();
  const dir = getDirection(router.locale);

  // updating creator to next
  useCookieUpdate();

  useEffect(() => {
    document.documentElement.dir = dir;
  }, [dir]);

  useEffect(() => {
    if (!Cookies.get('userToken')) {
      Cookies.set('userToken', createRandomString(10), { expires: 365 });
    }
    axiosInterceptor();
  }, []);

  const Layout = (Component as any).Layout || Noop;

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ManagedUIContext>
          <>
            <DefaultSeo />
            <ContentfulLivePreviewProvider
              locale="en-US"
              enableLiveUpdates={true}
              // enableInspectorMode={pageProps.draftMode}
              // debugMode={true}
            >
              <Layout pageProps={pageProps}>
                <Component {...pageProps} key={router.route} />
              </Layout>
            </ContentfulLivePreviewProvider>
            <ToastContainer />
            <ManagedModal />
            <ManagedDrawer />
          </>
        </ManagedUIContext>
      </Hydrate>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
};

export default appWithTranslation(CustomApp);

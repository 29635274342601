import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import TokenProvider from '@framework/utils/token-provider';
import { getAppConfig } from '@utils/get-app-config';

type DRAWER_VIEWS = 'CART_SIDEBAR' | 'MOBILE_MENU' | 'ORDER_DETAILS';

export type UiStore = {
  isAuthorized: boolean;
  displaySidebar: boolean;
  displayMobileAllCategories: boolean;
  displayFilter: boolean;
  displayCart: boolean;
  displaySearch: boolean;
  displayShop: boolean;
  displayMobileSearch: boolean;
  displayDrawer: boolean;
  drawerView: string | null;
  toastText: string;
  isStickyheader: boolean;
  data?: any;
  headerData?: any;
  categoryData?: any;
  userAvatar: any;
  authorize: () => void;
  unauthorize: () => void;
  openSidebar: () => void;
  closeSidebar: () => void;
  openMobileAllCategories: () => void;
  closeMobileAllCategories: () => void;
  openShop: () => void;
  closeShop: () => void;
  toggleSidebar: () => void;
  closeSidebarIfPresent: () => void;
  openCart: () => void;
  closeCart: () => void;
  toggleCart: () => void;
  closeCartIfPresent: () => void;
  openFilter: () => void;
  closeFilter: () => void;
  openDrawer: (data?: any) => void;
  closeDrawer: () => void;
  openSearch: () => void;
  closeSearch: () => void;
  openMobileSearch: () => void;
  closeMobileSearch: () => void;
  toggleMobileSearch: () => void;
  setDrawerView: (data: DRAWER_VIEWS) => void;
  setUserAvatar: (data: string) => void;
  enableStickyHeader: () => void;
  disableStickyHeader: () => void;
  setHeaderData: (data?: any) => void;
  setCategoryData: (data?: any) => void;
};

const authToken = TokenProvider.getToken();
const initialState = {
  isAuthorized: authToken ? !authToken.is_anonymous : false,
  displaySidebar: false,
  displayFilter: false,
  displayCart: false,
  displaySearch: false,
  displayShop: false,
  displayMobileSearch: false,
  displayMobileAllCategories: false,
  displayDrawer: false,
  drawerView: null,
  toastText: '',
  isStickyheader: false,
  data: null,
  headerData: null,
  userAvatar: '',
};

const config = getAppConfig();

const store: StateCreator<
  UiStore,
  [['zustand/devtools', never]],
  [],
  UiStore
> = (set, get) => ({
  ...initialState,
  authorize: () =>
    set(() => ({ isAuthorized: true }), false, 'uiStore/authorize'),
  unauthorize: () =>
    set(() => ({ isAuthorized: false }), false, 'uiStore/unauthorize'),
  openSidebar: () =>
    set(() => ({ displaySidebar: true }), false, 'uiStore/openSidebar'),
  closeSidebar: () =>
    set(
      () => ({ displaySidebar: false, drawerView: null }),
      false,
      'uiStore/closeSidebar',
    ),
  openMobileAllCategories: () =>
    set(
      () => ({ displayMobileAllCategories: true }),
      false,
      'uiStore/openMobileAllCategories',
    ),
  closeMobileAllCategories: () =>
    set(
      () => ({ displayMobileAllCategories: false }),
      false,
      'uiStore/closeMobileAllCategories',
    ),
  openShop: () => set(() => ({ displayShop: true }), false, 'uiStore/openShop'),
  closeShop: () =>
    set(
      () => ({ displayShop: false, drawerView: null }),
      false,
      'uiStore/closeShop',
    ),
  toggleSidebar: () => {
    get().displaySidebar ? get().closeSidebar() : get().openSidebar();
  },
  closeSidebarIfPresent: () => {
    get().displaySidebar && get().closeCart();
  },
  openCart: () => set(() => ({ displayCart: true }), false, 'uiStore/openCart'),
  closeCart: () =>
    set(() => ({ displayCart: false }), false, 'uiStore/closeCart'),
  toggleCart: () => {
    get().displaySidebar ? get().closeCart() : get().openCart();
  },
  closeCartIfPresent: () => {
    get().displaySidebar && get().closeCart();
  },
  openFilter: () =>
    set(() => ({ displayFilter: true }), false, 'uiStore/openFilter'),
  closeFilter: () =>
    set(() => ({ displayFilter: false }), false, 'uiStore/closeFilter'),
  openDrawer: (data) =>
    set(
      () => ({
        displayDrawer: true,
        displaySidebar: false,
        data,
      }),
      false,
      'uiStore/openDrawer',
    ),
  closeDrawer: () =>
    set(() => ({ displayDrawer: false }), false, 'uiStore/closeDrawer'),
  openSearch: () =>
    set(() => ({ displaySearch: true }), false, 'uiStore/openSearch'),
  closeSearch: () =>
    set(() => ({ displaySearch: false }), false, 'uiStore/closeSearch'),
  openMobileSearch: () =>
    set(
      () => ({ displayMobileSearch: true }),
      false,
      'uiStore/openMobileSearch',
    ),
  closeMobileSearch: () =>
    set(
      () => ({ displayMobileSearch: false }),
      false,
      'uiStore/closeMobileSearch',
    ),
  toggleMobileSearch: () =>
    set(
      (state) => ({ displayMobileSearch: !state.displayMobileSearch }),
      false,
      'uiStore/toggleMobileSearch',
    ),
  setDrawerView: (data: DRAWER_VIEWS) =>
    set(() => ({ drawerView: data }), false, 'uiStore/setDrawerView'),
  setUserAvatar: (data: string) =>
    set(() => ({ userAvatar: data }), false, 'uiStore/setUserAvatar'),
  enableStickyHeader: () =>
    set(() => ({ isStickyheader: true }), false, 'uiStore/enableStickyHeader'),
  disableStickyHeader: () =>
    set(
      () => ({ isStickyheader: false }),
      false,
      'uiStore/disableStickyHeader',
    ),
  setHeaderData: (data: any) =>
    set(() => ({ headerData: data }), false, 'uiStore/setHeaderData'),
  setCategoryData: (data: any) =>
    set(() => ({ categoryData: data }), false, 'uiStore/setCategoryData'),
});

const useUiStore = create<UiStore>()(
  devtools<UiStore>(store, { enabled: config.ENV !== 'production' }),
);

export default useUiStore;
